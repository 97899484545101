import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
   
    { title: 'Inicio', url: '/home', icon: 'home' },

    { title: 'El Proyecto', url: '/proyecto', icon: 'bulb' },

    { title: 'Indice', url: '/indice', icon: 'list' },

    { title: 'Introducción', url: '/intro-problema', icon: 'scan' },

    { title: 'Marco Teórico', url: '/marco-teorico', icon: 'book' },

    { title: 'Metodología', url: '/metodologia-tesis', icon: 'settings' },

    { title: 'Resultados', url: '/resultados', icon: 'easel' },

    { title: 'Discusiones', url: '/discusiones', icon: 'chatbubbles' },

    { title: 'Conclusiones', url: '/conclusiones', icon: 'hammer' },

    { title: 'Bibliografía', url: '/biblio', icon: 'library' },

    { title: 'Anexos Digitales', url: '/anexos', icon: 'ellipsis-horizontal' },

    { title: 'Enlaces', url: '/enlaces', icon: 'link' },
   
    { title: 'Glosario', url: '/glosario', icon: 'language' },

    { title: 'Publicaciones', url: '/public-congress', icon: 'documents' },

    { title: 'Acerca', url: '/acerca', icon: 'finger-print' }

    //{ title: 'Videoteca', url: '/videos', icon: 'play-circle' },
    
    //{ title: 'Mapas', url: '/mapagral', icon: 'location' },

  ];
 
  constructor() {}
}
