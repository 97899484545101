import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class HtmlLoaderService {

  constructor(private http: HttpClient) { }

  loadHtml(filePath: string): Observable<string> {
    return this.http.get(filePath, { responseType: 'text' });
  }

  loadMultipleHtml(filePaths: string[]): Observable<string[]> {
      const requests: Observable<string>[] = filePaths.map(path => this.loadHtml(path));
      return forkJoin(requests);
  }



}